import React, { ButtonHTMLAttributes, FC } from 'react';
import { Link } from '@remix-run/react';
import { RemixLinkProps } from '@remix-run/react/dist/components';

import { cn } from '~/utils/general';


type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  className?: string;
  inputProps?: Omit<React.SelectHTMLAttributes<HTMLButtonElement>, 'className'>;
  onClick: () => void;
};

/**
 * Standard StandardButton input.
 */
export const StandardButton: React.FC<Props> = (props) => {
  const { text, className, inputProps, onClick } = props;

  return (
    <button
      {...inputProps}
      className={cn([
        'rounded-full bg-dark-3 px-4 py-3 text-sm text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 active:enabled:scale-[0.99] transition-transform duration-75',
        className,
      ])}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
StandardButton.displayName = 'StandardButton';


type LinkProps = RemixLinkProps & {
  text?: string;
};

/**
 * Standard button, but as an anchor tag.
 */
export const StandardButtonLink: FC<LinkProps> = (props) => {
  return (
    <Link
      {...props}
      className={cn([
        'rounded-full bg-dark-3 px-4 py-3 text-sm text-white text-center shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 active:scale-[0.99] transition-transform duration-75',
        props.className,
      ])}
    >
      {props?.text || props?.children}
    </Link>
  );
};
StandardButtonLink.displayName = 'StandardButtonLink';


/**
 * Softer, flatter button variation.
 */
export const SecondaryButton: FC<ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  className?: string;
  inputProps?: Omit<React.SelectHTMLAttributes<HTMLButtonElement>, 'className'>;
  onClick: () => void;
}> = (props) => {
  const { text, className, inputProps, onClick } = props;

  return (
    <button
      {...inputProps}
      className={cn([
        'rounded-full px-4 py-3 text-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 active:scale-[0.99] transition-transform duration-75 bg-gray-2 border-1 border-gray-7 text-dark shadow-none hover:bg-gray-3 active:bg-gray-4',
        className,
      ])}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
SecondaryButton.displayName = 'SecondaryButton';